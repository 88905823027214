import {Helmet} from 'react-helmet';
import {useEffect, useRef} from 'react';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import clsx from 'clsx';
import Link, {TransitionState, useTriggerTransition} from 'gatsby-plugin-transition-link';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {Layout, Header, Container} from '../../components/layout';
import {Card, ZigZagLink} from '../../components';
import openPositions from '../../data/career';
import Hero from '../../components/layout/hero/hero';
import scrollTo from '../../utils/scrollTo';
import {isExiting} from '../../utils/animations';
import pageTypes from '../../utils/enums/pageType';
import crossPageAnimation from '../../utils/animations/crossPage';
import useIsMobile from '../../hooks/useIsMobile';
import * as styles from './index.module.css';

const CareerPage = () => {
    const isMobile = useIsMobile(900);

    const sendDataToGTM = useGTMDispatch();
    const allPositionsRef = useRef(null);
    const entryTransition = useTriggerTransition({
        to: '/career',
        entry: {
            length: 0.5,
            state: {
                pageType: pageTypes.career,
                nextPageType: pageTypes.career,
            },
        },
    });

    useEffect(() => {
        entryTransition({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout hideFooterButton={false}>
            <Helmet>
                <title>Career | Digital Vision CZ</title>
                <meta
                    name="description"
                    content="Chceš se podílet na projektech, kde bude tvoje práce skutečně vidět? Chceš vytvářet smysluplné věci pro smysluplné firmy?"
                />
            </Helmet>
            <Header pageType={pageTypes.career}>
                <Hero
                    headerType = 'blue'
                    title = 'Kariéra'
                    description = 'Jsme menší pražské digitální studio, ale pracujeme s firmami, které působí v různých oborech po celém světě. Propojujeme pro ně funkční design, moderní technologie a online marketing.'
                    backLinkPath = {false}
                    backLinkText = 'text'
                />
            </Header>
            <TransitionState>
                {({transitionStatus}) => {
                    const exiting = isExiting(transitionStatus);

                    return (
                        <div className={clsx(styles.root, exiting && styles.exiting)}>
                            {openPositions.length > 0 && (
                                <Container background="gray">
                                    <div className={styles.featuredPositions}>
                                        <div className={styles.hideOnMobile}>
                                            {openPositions.filter(item => item.featured).map(position => (
                                                <Card
                                                    key={`position-${position.detailSlug}`}
                                                    title={position.title}
                                                    description={position.listDescription}
                                                    to={`/career/${position.detailSlug}`}
                                                    color={position.listColor}
                                                    section='featuredPositions'
                                                />
                                            ))}
                                        </div>
                                        <span className={styles.allPositionsLink}>
                                            <ZigZagLink
                                                text={isMobile ? 'Volné pozice' : 'Všechny volné pozice'}
                                                onClick={() => {
                                                    sendDataToGTM({
                                                        event: 'imageClick',
                                                        imageAlt: 'zigZagLink',
                                                        type: 'anchor',
                                                        targetURL: '/career',
                                                    });
                                                    scrollTo(allPositionsRef);
                                                }}
                                            />
                                        </span>
                                    </div>
                                </Container>
                            )}
                            <Container>
                                <div className={styles.whoAreWe}>
                                    <div className={styles.whoAreWeLeft}>
                                        <strong>Potkávají se u nás lidi různých specializací.</strong> S různými úhly
                                        pohledu. Každý názor tu má svůj prostor a váhu
                                        a pomáhá nám společně se dopracovat k nejvhodnějšímu řešení.
                                    </div>
                                    <div className={styles.whoAreWeRight}>
                                        <strong>Rádi vymýšlíme věci společně.</strong> A dost času pracujeme i samostatně.
                                        To neznamená, že se nemůžeš kolegy zeptat na názor nebo poprosit o radu. Ale že každý
                                        neseme zodpovědnost za svoji část práce.
                                    </div>
                                    <div className={styles.whoAreWeLeft}>
                                        <strong>Chceme dělat věci dobře.</strong> Aby dělaly radost nejen klientům a uživatelům,
                                        ale i nám. Abychom na ně mohli být hrdí.<br/>
                                        <Link
                                            to="/cases"
                                            onClick={() => {
                                                sendDataToGTM({
                                                    event: 'linkClick',
                                                    linkText: 'Podívej se, jaké problémy už jsme vyřešili a jak.',
                                                    targetURL: '/cases',
                                                });
                                            }}
                                            exit={{
                                                length: 0.7,
                                                state: {
                                                    pageType: pageTypes.career,
                                                    nextPageType: pageTypes.cases,
                                                    linkTo: '/cases',
                                                    crossPage: true,
                                                },
                                            }}
                                            entry={{
                                                length: 0.5,
                                                delay: 0.7,
                                                state: {
                                                    pageType: pageTypes.career,
                                                    nextPageType: pageTypes.cases,
                                                    linkTo: '/cases',
                                                },
                                            }}
                                            trigger={() => {
                                                crossPageAnimation();
                                            }}
                                        >
                                            Podívej se, jaké problémy už jsme vyřešili a jak.
                                        </Link>
                                    </div>
                                    <div className={styles.whoAreWeRight}>
                                        <strong>Vážíme si lidí, kteří jsou kreativní, otevření a zapálení pro věc.</strong> Rádi
                                        se učíme jeden od druhého a každý z nás se může rozvíjet směrem, který mu dává smysl.
                                        A taky ovlivnit, kam se budeme dál ubírat jako tým.
                                    </div>
                                </div>
                            </Container>
                            <Container
                                type="small"
                                isCentered={true}
                                background="yellow"
                            >
                                <div className={styles.writeUs}>
                                    <p>
                                        Chceš se podílet na projektech, kde bude tvoje práce skutečně vidět?
                                        Chceš vytvářet smysluplné věci pro smysluplné firmy?
                                    </p>
                                    <AnchorLink to="/contact#contact" stripHash>
                                        <button
                                            onClick={() => {
                                                sendDataToGTM({
                                                    event: 'buttonClick',
                                                    buttonText: isMobile ? 'Napiš nám' : 'Napiš nám, jaká je tvoje vize',
                                                    targetURL: '/contact#contact',
                                                });
                                            }}
                                            className={styles.emailButton}
                                        >
                                            {isMobile ? 'Napiš nám' : 'Napiš nám, jaká je tvoje vize'}
                                        </button>
                                    </AnchorLink>
                                </div>
                            </Container>
                            {openPositions.length > 0 && (
                                <Container background="gray">
                                    <div className={styles.featuredPositions} ref={allPositionsRef}>
                                        <h5>Právě hledáme</h5>
                                        <div>
                                            {openPositions.map(position => (
                                                <Card
                                                    key={`position-${position.detailSlug}`}
                                                    title={position.title}
                                                    description={position.listDescription}
                                                    to={`/career/${position.detailSlug}`}
                                                    color={position.listColor}
                                                    section='allPositions'
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Container>
                            )}
                            <Container type="small" isCentered={true}>
                                <div className={styles.noPositions}>
                                    {openPositions.length === 0 && (
                                        <>
                                            <h5>Aktuálně nikoho konkrétního nehledáme.</h5>
                                            Ale pokud máš pocit, že přemýšlíme podobně, napiš nám, co umíš a kam směruješ.
                                            A probereme to u kafe. Třeba tě potřebujeme, jen o tom nevíme.
                                        </>
                                    )}
                                    {openPositions.length > 0 && (
                                        <>
                                            Možná zrovna nehledáme člověka s tvým zaměřením. Ale pokud máš pocit, že přemýšlíme podobně,
                                            napiš nám i tak. A probereme to u kafe. Třeba tě potřebujeme, jen o tom nevíme.
                                        </>
                                    )}
                                </div>
                            </Container>
                        </div>
                    );
                }}
            </TransitionState>
        </Layout>
    );
};

export default CareerPage;
