import {gsap} from 'gsap';

const crossPageAnimation = () => {
    const pageCrossWrapper = document.querySelector('[data-crosspage-wrapper');
    const pageCrossBackgroundEl = document.querySelector('[data-crosspage-background');
    const pageCrossLogoEl = document.querySelector('[data-crosspage-logo');

    const pagecrossTimeline = gsap.timeline({
        onComplete: () => {
            gsap.to(pageCrossWrapper, {
                zIndex: -1,
                opacity: 0,
                display: 'none',
                duration: 0,
            });
            gsap.to(pageCrossBackgroundEl, {left: 'calc(-100vw - 30vw - 386px)', duration: 0});
            gsap.to(pageCrossLogoEl, {opacity: 0, left: 0, duration: 0});
        },
    });

    gsap.to(pageCrossWrapper, {zIndex: 10, opacity: 1, display: 'block'});

    pagecrossTimeline.to(pageCrossBackgroundEl, {left: 0, duration: 1, ease: 'sine.out'});
    pagecrossTimeline.to(pageCrossLogoEl, {opacity: 1, duration: 0.5}, '>-0.6');
    pagecrossTimeline.to(pageCrossBackgroundEl, {left: 'calc(100vw + 30vw + 398px)', duration: 1, ease: 'sine.out'}, '>+0.5');
    pagecrossTimeline.to(pageCrossLogoEl, {left: '100vw', duration: 0.75, ease: 'sine.inOut'}, '>-1');
};

export default crossPageAnimation;
